<template xmlns="http://www.w3.org/1999/html">
  <div class="main main--internal">
    <div class="page-intro">
      <div class="main-intro">
        <div class="page-intro__left">
          <div class="page-header__container">
            <h1 class="page-header page-header_w500">{{ title }}</h1>
          </div>
          <article class="article">
            <div class="article__block">
              <div class="article__content article__content_w500">
                <EditorJSComponent :text="description.text"/>
              </div>
            </div>
          </article>
          <div class="page-intro__forms">
            <div class="form-group">
              <div class="form-group__header">
                <span class="form-group__header__title">Вы хотите отменить дорожный штраф?</span>
              </div>
              <div class="form-group__body">
                <div class="form-group__body__radio">
                  <label class="form-group__radio">
                    <input type="radio" v-model="stepForm.agree" :value="1">
                    <RadioStatesIcon/>
                    <span class="form-group__radio__title">Да</span>
                  </label>
                  <label class="form-group__radio">
                    <input type="radio" v-model="stepForm.agree" :value="0">
                    <RadioStatesIcon/>
                    <span class="form-group__radio__title">Нет</span>
                  </label>
                </div>
              </div>
            </div>
            <div>
              <a href="#" :class="['button button--fill', { 'button--disabled': !stepForm.agree}]"
                @click.prevent="showPopUp">
                Начать
              </a>
            </div>
          </div>
        </div>
        <div class="page-intro__right">
          <div class="example-claim">
            <img src="/public/img/example_claim.png" alt="">
            <p>Пример готового иска</p>
            <div class="flex-center" sp-show-form="205713">
              <a  class="button button__blue" href="#">Скачать</a>
            </div>
          </div>
        </div>
      </div>
      <section class="main__faq" v-if="faq && faq.length">
        <h4>Часто задаваемые вопросы</h4>
        <ul class="article__faq__list">
          <li class="article__faq__list__item" :class="{ active: activeFaq === index }" v-for="(item, index) in faq"
              :key="item.id">
            <div class="article__faq__list__item__header" @click="setActiveFaq(index)">
              <h3>{{ item.question }}</h3>
              <div>
                <PlusIcon/>
                <MinusIcon/>
              </div>
            </div>
            <p class="article__faq__list__item__text">{{ item.answer }}</p>
          </li>
        </ul>
      </section>
    </div>
  </div>
</template>

<script>
import PlusIcon from 'components/svg/Plus.vue'
import MinusIcon from 'components/svg/Minus.vue'
import RadioStatesIcon from 'components/svg/RadioStates.vue'
import EditorJSComponent from 'components/EditorJSComponent.vue'

export default {
  name: 'IntroTraffic',
  components: {
    PlusIcon,
    MinusIcon,
    RadioStatesIcon,
    EditorJSComponent
  },
  async asyncData ({ store }) {
    await store.dispatch('GET_TRAFFIC_PREVIEW_PAGE')
  },
  data () {
    return {
      activeFaq: null,
      stepForm: {
        agree: null
      }
    }
  },
  computed: {
    title () {
      return this.$store.state.traffic_preview_page.preview.title
    },
    description () {
      return this.$store.state.traffic_preview_page.preview
    },
    faq () {
      return this.$store.state.traffic_preview_page.preview.questions
    }
  },
  metaInfo () {
    const navigator = this.$store.state.traffic_preview_page.preview
    return this.$seo(
      'common',
      navigator.meta_title,
      navigator.meta_keywords,
      navigator.meta_description
    )
  },
  methods: {
    setActiveFaq (index) {
      if (this.activeFaq === index) {
        this.activeFaq = null
      } else {
        this.activeFaq = index
      }
    },
    showPopUp () {
      if (this.stepForm.agree) this.$swal('Этот бот находится в разработке')
    }
  }
}
</script>
